<template>
  <a
    :href="`tel:${numberHref}`"
  >
    <slot>{{ numberDispl }}</slot>
  </a>
</template>

<script>
export default {
  props: {
    numberDispl: {
      type: String,
      default: "0711 / 6555 7011"
    },
    numberHref: {
      type: String,
      default: "+4971165557011"
    }
  }
}
</script>
