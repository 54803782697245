<template>
  <div class="togo-terms">
    <div class="togo-terms__hint">
      <span
        class="togo-terms__actiontxt"
        @click="$store.commit('togoTerms/setShowDimmer', true)"
      >
        Infos zu Lieferung und Abholung
      </span>
    </div>
    <TogoTermsDimmer v-show="showDimmer" />
  </div>
</template>

<script>
import {mapState} from "vuex"
import TogoTermsDimmer from "@/components/TogoTermsDimmer.vue"

export default {
  components: {
    TogoTermsDimmer
  },

  computed: {
    ...mapState({
      showDimmer: state => state.togoTerms.showDimmer
    })
  }
}
</script>

<styles lang="scss">
.togo-terms__hint {
  text-align: center;
}

.togo-terms__actiontxt {
  text-decoration: underline;
  cursor: pointer;
}
</styles>
