<template>
  <BaseDimmer
    class="togo-terms-dimmer"
    @base-dimmer-hide="$store.commit('togoTerms/setShowDimmer', false)"
  >
    <template #title>
      Lieferung & Abholung
    </template>
    <template #body>
      <p>
        Sie bestellen Ihr Wunsch-Gericht einfach per Telefon - wir liefern prompt und kontaktlos!
        <br>
        <LinkTel />
      </p>
      <p>
        Sie bezahlen mit Kreditkarte und unser Fahrer bringt das Essen direkt vor die Tür oder Sie kommen einfach bei uns in der Bolzstraße zum Abholen vorbei.
      </p>
      <p>
        Wir liefern im Umkreis von 25 km.
      </p>
      <p>
        Längere Strecken nach Vereinbarung.
        <br>
        Lieferung 1 km + 5 € pro Bestellung
        <br>
        Lieferung 1 bis 6 km + 9 € pro Bestellung
        <br>
        Lieferung 6 bis 10 km + 15 € pro Bestellung
        <br>
        Lieferung 10 bis 15 km + 30 € pro Bestellung
        <br>
        Lieferung 15 bis 25 km + 40 € pro Bestellung
        <br>
      </p>
      <p>
        Es wäre schön, wenn Sie fleißig bestellen, so dass auch wir durch diese schwierige Zeit kommen.
      </p>
    </template>
  </BaseDimmer>
</template>

<script>
import BaseDimmer from "@/components/BaseDimmer.vue"
import LinkTel from "@/components/LinkTel.vue"

export default {
  components: {
    BaseDimmer,
    LinkTel
  }
}
</script>

<styles lang="scss">
.togo-terms-dimmer {
  p {
    text-align: center;
  }
}
</styles>
