<!-- Emits base-dimmer-hide on user action requesting hide. -->

<template>
  <section
    class="base-dimmer"
    @click="handleClick"
  >
    <div class="base-dimmer__fbox">
      <div
        ref="content"
        class="base-dimmer__content"
      >
        <div class="base-dimmer__header">
          <h1 class="base-dimmer__headertxt">
            <slot name="title" />
          </h1>
          <div
            ref="close"
            class="base-dimmer__close"
          >
            <slot name="close">
              <NucleoGERemove />
            </slot>
          </div>
        </div>
        <div class="base-dimmer__body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NucleoGERemove from "./svg/NucleoGERemove.vue"

export default {
  components: {
    NucleoGERemove
  },

  methods: {
    /** @param {Object} e - DOM event */
    handleClick(e) {
      const t = e.target
      if (!this.$refs.content.contains(t) || this.$refs.close.contains(t)) {
        this.$emit("base-dimmer-hide")
      }
    }
  }
}
</script>

<style lang="scss">
@use "svg";
@use "vars";

.base-dimmer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  padding: 2em vars.$body-padding-h;
  background: rgba(0,0,0,0.5);
  overflow: auto;
}

.base-dimmer__fbox {
  display: flex;
  justify-content: center;
  // 'align-items: center' may hide top -> auto margins on .modal-content
  width: 100%;
  height: 100%;
}

.base-dimmer__content {
  width: 100%;
  max-width: 40em;
  margin: auto 0;
  color: vars.$white;
  background-color: vars.$beige;
}

.base-dimmer__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 0;
  margin: 0 vars.$body-padding-h;
  border-bottom: 1px solid;
}

.base-dimmer__headertxt {
  font-family: vars.$font-fam-deco;
  font-size: 1em;
  font-weight: normal;
  text-transform: uppercase;
}

.base-dimmer__close {
  margin-left: 1em;
  svg {
    display: block;
    $d: 1em;
    width: $d;
    height: $d;
    @include svg.nucleo-g-e-remove(vars.$white);
  }
  svg:hover {
    cursor: pointer;
  }
}

.base-dimmer__body {
  position: relative;
  padding: 2em vars.$body-padding-h;
  p {
    margin-top: 1.5em;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
